
.properties_listing{ margin-top: 130px !important;}

.property-card{ background: #FFF; box-shadow: 0px 0px 20px 0px rgba(174, 168, 168, 0.25); border-radius: 10px; -webkit-border-top-left-radius: 7px;
    -webkit-border-top-right-radius: 7px;
    -moz-border-radius-topleft: 7px;
    -moz-border-radius-topright: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; margin-bottom: 50px; position: relative;  }
.property-card img{ width: 100%; -webkit-border-top-left-radius: 7px; object-fit: none;
    -webkit-border-top-right-radius: 7px;
    -moz-border-radius-topleft: 7px;
    -moz-border-radius-topright: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; }
.property-card h2{ 
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal; color: #000;}
    .bottom_property {
        padding: 20px;
    }
    .location_name {
        height: 30px;
        margin-left: 8px; color: #787D8C;
    margin-top: 2px;
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
           white-space: break-spaces;
    }

    .single_amenities.d-flex {
        margin-left: 0px;
/*         margin-top: 22px;
 */        white-space: normal;
    }
    .price {
        
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 5px; color: #000;
    }

  .price-amenities { margin-top: 15px; justify-content: space-between;}     
  .area{ color: #787D8C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; } 
    .single_amenities.d-flex {
        margin-left: 17px;
    }
    .right_amenities {
        color: #000;
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 5px;
        margin-left: 7px;
        
    }

    .left-overlays .featured{ border-radius: 15px;
        border: 2px solid #22A9E0;
        background: #22A9E0; padding: 4px 12px; font-size: 12px;
        font-style: normal;
        font-weight: 400;
         color: #fff;}

         .property-overlays {
            position: absolute;
            top: 10px;
            justify-content: space-between !important;
            left: 10px;
            width: 100%;
        }

        .hot{ background: #000 !important; border: none !important; margin-left: 5px;}

        .right_single_block {
            border-radius: 20px;
            background: #FFF;
            padding: 2px 10px;
            height: 23px;
            margin-left: 12px;
        }
        .left_single_icon {
            margin-right: 5px;
        }
        .right_single_text {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-top: 1px;
            color: #22A9E0;
        }
        .left_single_icon img{ margin-top: -10px;}
        .right-overlays.d-flex {
            margin-top: 3px;
        }
        .right-overlays.d-flex {
            margin-top: 3px;
            margin-right: 20px;
        }
        .circle_heart {
           
            height: 30px;
            width: 30px;
            border-radius: 50%;
            padding: 1px;
            text-align: center;
            border-radius: 500px;
            margin-left: 10px;
            margin-top: -4px;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
        .circle_heart{ background: none !important;}

        .circle_heart img {
            margin-top: -4px;
            margin-left: 0px;
            position: relative;
            left: 2px;
            height: 32px;
            width: 32px;
        }

        .left_caro {
            height: 40px;
            width: 40px;
            border: 1px solid #22A9E0;
            text-align: center;
            border-radius: 500px;
            padding: 1px;
            background: #fff;
            font-size: 23px;
            color: #22A9E0;
            padding-left: 0px;
            margin-right: 15px;
        }
        .right_caro{ margin-right: 0px !important; margin-left: 15px !important;}

        .slider-caros {
            justify-content: center;
            cursor: pointer;
            margin-top: 22px;
        }

        .left_caro:hover{ color: #fff; background: #22A9E0; }
        .middle_dots {
            background: #fff;
            border-radius: 61px;
        }
        .middle_dots{
             border-radius: 50px;
            background:#FFF;
            box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.19); padding: 0px 10px;}
        .middle_dots ul {
            margin: 0px;
            padding: 0px; 
        }
        .middle_dots ul li {
            display: inline-block;
            cursor: pointer;
            list-style-type: circle;
           
            background: #22A9E0;
            border-radius: 500px;
            padding: 3px;
            margin: 13px;
        }

        .middle_dots {
            height: 30px;
            margin-top: 5px;
        }

       .listing_btn {
    margin-top: -31px;
    text-align: right;
    /* z-index: 9999;
    position: relative; */
}



.just_listed{ margin-top: 100px; margin-bottom: 50px;}
.recent_listed h2{ margin-bottom: 80px;}

.just_listed .single_amenities {
    margin-left: 0px;
   
}

.just_listed .amenities { margin-top: 15px;  gap: 20px;}
.just_listed .price {
    font-size: 24px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;
    margin-top: 38px;
}

.just_listed .property-card {
    display: flex!important;
    align-items: center !important;
}
.just_listed .left_property_image img {
    border-radius: 10px;
    height: 100%;
    object-fit: none;
}
.just_listed .property-card{ margin-bottom: 25px !important;}
.just_listed .left_property_image {
    width: 50%;
}


.just_listed .property-card{ padding-left: 0px !important;}



@media (min-width: 1280px) and (max-width: 1400px) {
    .price{ font-size: 14px;}

.just_listed .left_property_image {
    width: 46%;
}
.just_listed .left_property_image img {
    height: 204px;
}
.right_amenities{ font-size: 13px;}

}
        

@media (min-width: 1025px) and (max-width: 1083px) {
    .price-amenities {
        flex-wrap: wrap;
    }
    .price {
        width: 100%;
    }
    .amenities.d-flex {
        gap: 23px;
    }
    .single_amenities.d-flex {
        margin-left: 0px;
        margin-top: 22px;
    }

  

}

@media (min-width: 992px) and (max-width: 1199px) {
    .halip{ width: 100% !important; margin-left: 0% !important;}
    .ppo {
        margin-top: 0px !important;
        position: relative;
        top: -17px;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
.mtdn { margin-top: 17px !important;}

}


@media (min-width: 768px) and (max-width: 991px) {
a.btn.btn-primary.w-100.custom_search {
    padding-top: 10px;
    margin-top: 30px;
}

}


@media (min-width: 320px) and (max-width: 480px) {

.set_feat_home_only .property_drop {
    position: absolute;
    border-radius: 10px;
    background: var(--color-white-pure, #FFF);
    box-shadow: 0px 8px 10px 0px rgba(20, 25, 40, 0.25);
    min-width: 300px;
    top: 42px;
    left: 0%;
    z-index: 9999;
    padding: 20px;
}

}