.error{
    font-size: 12px;
}





@media (min-width: 320px) and (max-width: 1024px) {


#handler_only .right_input_blocks {
    margin-top: 0px;
    width: 50% !important;
}

}
