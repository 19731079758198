

.brand_set {
    width: 145px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .brand_set img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

.overall_nav .nav-link { color: #fff; text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: capitalize;}
.overall_nav .nav-link:hover { color: #fff;}
.overall_nav .nav-link.show{ color: #fff !important;}
.overall_nav li.nav-item.dropdown {
    padding: 0px 30px;
}

.logins{ padding-left: 30px;}

.overall_nav a.dropdown-item {
    font-size: 15px;
    color: #5a5a5a;
}

.overall_nav a.dropdown-item:hover { background: #22A9E0; color: #fff;}


/* modal */


.custom-modal-width {
    max-width: 900px !important;
}
.full_modal{ display: flex; justify-content: space-between; flex-wrap: wrap; }
.left_modal {
    width: 48%;
    padding: 15px 30px;
    padding-top: 30px;
}
.modal-body{ padding: 0px !important;}
.right_modal {
    width: 48%;
    padding: 48px 5px;
    padding-bottom: 0px;
    border-radius: 0px 20px 20px 0px;
    background: var(--color-white-blue, #F0F5FF);
    padding-top: 70px;
    padding-bottom: 30px;
}

.right_modal h2{ color: var(--color-secondary-dark, #141928);
    text-align: center;
    
   margin-bottom: 24px !important;
   
    font-size: 32px !important;
   
    font-weight: 700;
    }
.right_modal p{ color: var(--color-tertiary-grey-mid, #787D8C);
    text-align: center;
    
    
    font-size: 14px;
   
    font-weight: 400;
    line-height: 150%; }
    .foot_info {
        color: var(--color-tertiary-grey-mid, #787D8C);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 86px;
        padding: 0px 6px;
    }

    .foot_info a{ color: var(--color-secondary-dark, #141928) !important;

        
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        text-decoration-line: underline !important; cursor: pointer;}

        .left_modal {
           
            margin: 0 auto;
           
        }
        .right_modal p {
            padding: 0px 65px;
        }
        
        .tab-navigation {
            display: flex;
            justify-content: space-between; margin-bottom: 36px;
            
        }
        
        .tab-button {
            flex: 1;
            border: none;
            background: none;
            padding: 10px 0;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            color: var(--color-tertiary-grey-light, #B4B9C8);
            cursor: pointer;
            outline: none;
            position: relative;
        }
        
        .tab-button::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
            width: 145px;
            height: 2px;
            background-color: #B4B9C8;
        }
        
        .tab-button.active {
            color: var(--color-secondary-dark, #141928);
        }
        
        .tab-button.active::after {
            background-color: #141928;
        }
        

        .left_modal .form-control{ border-radius: 5px;
            border: 1px solid var(--color-white-blue, #F0F5FF); color: var(--color-tertiary-grey, #646978);
padding-left: 0px;
           height: 42px;
            font-size: 14px;
           border-left: 0px;
            font-weight: 400;
            line-height: 150%; }

            .bg_nol  {
                border: 1px solid var(--color-white-blue, #F0F5FF) !important;
                background: none !important;
                border-right: 0px !important;
                box-shadow: none !important; 
            }

 .fgt{ color: var(--color-tertiary-grey-light, #B4B9C8) !important;
    text-align: right;
    
   
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; }   
    
    .login_btn{ border-radius: 4px;
        background: #22A9E0 !important;
        box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50) !important; min-height: 47px;
        padding: 8px 50px 8px 50px !important; color: var(--color-white-pure, #FFF);
        text-align: center;
       
        font-size: 15.5px !important;
        font-weight: 500; color: #fff !important;
        line-height: 16px; margin-top: 20px; }

        .or_login{ color: #3C4150;
margin-top: 45px; margin-bottom: 16px;
          
            font-size: 14px;
          
            font-weight: 400;
            line-height: 150%; }



.cur{ cursor: pointer;}




            .sbtn .custom-btn {
                border-radius: 10px;
                border: 1px solid var(--color-white-blue, #F0F5FF);
                color: var(--color-tertiary-grey, #646978);
                font-family: Oxygen;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; 
                padding: 8px 16px;
                display: inline-flex;
                align-items: center;
                background-color: transparent; 
                cursor: pointer;
                transition: background-color 0.3s ease; min-width: 245px; text-align: center; min-height: 42px;
            }
            
            .sbtn .custom-btn:hover {
                background-color: var(--color-white-blue, #F0F5FF);
            }
            
            .sbtn .facebook-icon {
                
                width: 20px; 
                height: 20px;
                margin-right: 8px; 
                display: inline-block;
                background-size: cover;
                background-repeat: no-repeat;
            }
            
            .sbtn {
                text-align: center;
                margin: 13px;
            }
            .sbtn a{ display: block;}

            .sbtn img{ margin-right: 24px;}


.modal_closer{ position: absolute; right: 20px; top: 20px; z-index: 9999; cursor: pointer;}            
            

.forgot-password-content h2{color: var(--color-secondary-dark, #141928);
    text-align: center;
    
    /* Heading 5 */
   
    font-size: 20px !important;
   
    font-weight: 700 !important; position: relative;
    }

    .forgot-password-content h2::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        width: 220px;
        height: 2px;
        background-color: #141928;
        top: 37px;
    }
    .fpass{ margin-top:30px;}

    .forgot-password-content p{ text-align: center; margin-top: 40px; color: #3C4150;

       
        font-size: 14px;
      
        font-weight: 400;
        line-height: 150%;}

        .or_login a{ color: var(--color-secondary-dark, #141928);
            text-align: center;
            font-family: Oxygen;
            font-size: 14px;
            font-style: normal;
            font-weight: 700 !important;
          
            text-decoration-line: underline !important;}

            .fgt a{ color: var(--color-tertiary-grey-light, #B4B9C8);
                text-align: right;
                
               
              
                font-size: 12px;
                font-weight: 400;
                line-height: 150%; }

                .successful {
                    margin: 0 auto;
                    text-align: center;
                    margin: 60px 0px;
                    margin-bottom: 50px;
                }
                .scful a{ color: var(--color-secondary-dark, #22A9E0) !important;
                  
                    font-size: 14px;
                 
                    font-weight: 700;
                    line-height: 150%; 
                    text-decoration-line: underline;}

                    .successful img{ width: 50%;}

                    @media (min-width: 320px) and (max-width: 1024px) {

                    
                        .logins {
                            padding-left: 30px;
                            list-style-type: none;
                            padding: 10px 27px;
                        }

                    }
/* Add the following CSS to your existing styles or create a new CSS file */
.dropdown-menu.show {
z-index: 1002;
  }
  
 

  .user_blocker{ width: 32px; border-radius: 500px;}

  .login-dropper .dropdown-toggle::after{ display: none !important;}

  .custom_log_droping{ border-radius: 4px !important;
    background: var(--Color-White-Pure, #FFF) !important;
    box-shadow: 0px 0px 20px 0px rgba(72, 72, 72, 0.20) !important;}

    .custom_log_droping .dropdown-item{ color: var(--Color-Tertiary-Grey, #646978) !important;
        font-family: Oxygen !important;
        font-size: 16px !important; 
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.32px;
        text-transform: capitalize;}

        .custom_log_droping .dropdown-item img{ margin-right: 12px !important;}

        .last_drop_item {
            border-top: 1px solid #B4B9C8 !important;
            margin-bottom: 0px;
            width: 80%;
            text-align: center;
            margin: 0 auto;
            margin-top: 7px;
            margin-bottom: 4px;
        }

        .custom_log_droping .dropdown-item:hover{ background: none !important; color: #22A9E0 !important;} 

        .profile_entries{ text-align: center;}

        .profile_entries .tab-button{ color: var(--Color-Secondary-Dark, #141928);
            text-align: center;
            
            /* Heading 5 */
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; }

           .profile_entries .tab-button::after{background-color: #141928;}
           .image_uploader{ padding: 20px 0px; padding-top: 30px;}
           .image_upload_profile img{ height: 140px; width: 140px; border-radius: 500px; background: #efefef;}
           .image_upload_profile{ position: relative;}

           .image_edit_icon img {
            height: 30px;
            width: 30px;
            border-radius: 5px !important;
            border: 1px solid #d1d1d1;
        }
        .image_edit_icon {
            position: absolute;
            right: 119px;
            bottom: 9px;
        }
        .single_fields {
            position: relative;
            margin-top: 18px;
        }

        .single_fields .form-control{ border-radius: 5px !important;
            border: 1px solid var(--Color-White-Blue, #F0F5FF); color: var(--Color-Tertiary-Grey, #646978) !important;

            font-size: 14px !important;
            font-weight: 400 !important; border-left: 1px solid var(--color-white-blue, #F0F5FF) !important; padding-left: 40px !important;
          }

          .u_icon {
            position: absolute;
            top: 9px;
            left: 10px;
        }

        .change_password{ color: var(--Color-Tertiary-Grey-light, #B4B9C8);
            text-align: right;font-size: 12px;font-weight: 400; border: none; background: none;
           }

           button.change_password {
            text-align: right;
            width: 100%;
            margin-top: 9px;
        }
        .logout {
            border-radius: 4px;
            background: var(--Color-Primary-Blue, #22A9E0) !important;
            box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);
            padding: 11px 64.405px 11px 64.595px;
            border: none;
            color: #fff;
            margin-top: 80px;
            margin-bottom: 30px;
        }

        .make_sure{ text-align: left; color: #3C4150;

          
            font-size: 14px;
            font-style: normal;
            font-weight: 400; margin-top: 30px; margin-bottom: 30px;
            }

            .save_btn{ text-align: left !important; margin-top: 40px;}

            .change_pass{ text-align: left;}


            .back_to_profile {
                margin-top: 44%;
                text-align: center; cursor: pointer; color: var(--Color-Secondary-Dark, #141928);
                text-align: center;
                font-family: Oxygen;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 21px */
                text-decoration-line: underline;
            }
            .modal-backdrop {
  z-index: -1;
}
/* .modal-backdrop {
  display: none !important;
} */
