.properties_listing{ margin-top: 130px !important;}
.property-card{ background: #FFF; box-shadow: 0px 0px 20px 0px rgba(174, 168, 168, 0.25); border-radius: 10px; -webkit-border-top-left-radius: 7px;
    -webkit-border-top-right-radius: 7px;
    -moz-border-radius-topleft: 7px;
    -moz-border-radius-topright: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; margin-bottom: 50px; position: relative;  }
.property-card img{ width: 100%; -webkit-border-top-left-radius: 7px;
    -webkit-border-top-right-radius: 7px;
    -moz-border-radius-topleft: 7px;
    -moz-border-radius-topright: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; }
.property-card h2{ 
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal; color: #000;}
    .bottom_property {
        padding: 20px;
    }
    .location_name {
        margin-left: 8px; color: #787D8C;
    margin-top: 2px;
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .price {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 5px; color: #000;
    }
  .price-amenities { margin-top: 15px; justify-content: space-between;}     
  .area{ color: #787D8C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; } 
    .single_amenities.d-flex {
        margin-left: 17px;
    }
    .right_amenities {
        color: #000;
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 5px;
        margin-left: 7px;
    }
    .left-overlays .featured{ border-radius: 15px;
        border: 2px solid #22A9E0;
        background: #22A9E0; padding: 4px 12px; font-size: 12px;
        font-style: normal;
        font-weight: 400;
         color: #fff;}
         .property-overlays {
            position: absolute;
            top: 10px;
            justify-content: space-between !important;
            left: 10px;
            width: 100%;
        }
        .hot{ background: #000 !important; border: none !important; margin-left: 5px;}
        .right_single_block {
            border-radius: 20px;
            background: #FFF;
            padding: 2px 10px;
            height: 23px;
            margin-left: 12px;
        }
        .left_single_icon {
            margin-right: 5px;
        }
        .right_single_text {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-top: 1px;
            color: #22A9E0;
        }
        .left_single_icon img{ margin-top: -10px;}
        .right-overlays.d-flex {
            margin-top: 3px;
        }
        .right-overlays.d-flex {
            margin-top: 3px;
            margin-right: 20px;
        }
        .circle_heart {
            background: #fff;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            padding: 1px;
            text-align: center;
            border-radius: 500px;
            margin-left: 10px;
            margin-top: -4px;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
        .circle_heart img {
            margin-top: -4px;
            margin-left: 0px;
            position: relative;
            left: 2px;
        }
        .left_caro {
            height: 40px;
            width: 40px;
            border: 1px solid #22A9E0;
            text-align: center;
            border-radius: 500px;
            padding: 1px;
            background: #fff;
            font-size: 23px;
            color: #22A9E0;
            padding-left: 0px;
            margin-right: 15px;
        }
        .right_caro{ margin-right: 0px !important; margin-left: 15px !important;}
        .slider-caros {
            justify-content: center;
            cursor: pointer;
            margin-top: 22px;
        }
        .left_caro:hover{ color: #fff; background: #22A9E0; }
        .middle_dots {
            background: #fff;
            border-radius: 61px;
        }
        .middle_dots{
             border-radius: 50px;
            background:#FFF;
            box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.19); padding: 0px 10px;}
        .middle_dots ul {
            margin: 0px;
            padding: 0px; 
        }
        .middle_dots ul li {
            display: inline-block;
            cursor: pointer;
            list-style-type: circle;
         /*    background: #22A9E0; */
            border-radius: 500px;
            padding: 3px;
            margin: 11px;
            height: 8px;
            width: 8px; position: relative;
            top: -17x;
        }
        .middle_dots {
            height: 30px;
            margin-top: 5px;
        }
        .listing_btn {
            text-align: right;
            margin-top: -31px;
        }
        .middle_dots_home ul {
            margin: 0px;
            padding: 0px;
            padding: 0px 20px;
        }
        .middle_dots_home ul li {
            display: inline-block;
            cursor: pointer;
            list-style-type: none;
            background: #22A9E0;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            margin: 12px 15px;
            padding: 3px;
            transition: transform 0.3s, box-shadow 0.3s;
        }
        .middle_dots_home ul li.active {
            background: #22A9E0;
            box-shadow: 0 0 0 2px #FFF, 0 0 0 4px #22A9E0; 
            transform: scale(1); 
        }
        .middle_dots_home {
            background: #FFF;
            border-radius: 50px;
            box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.19);
            height: 30px; 
            margin-top: 5px;
            display: flex;
        }        


        .flexer_btns {     justify-content: center;}
        .right_flexer {
            margin-top: 61px;
        }

        .right_flexer {
            margin-top: 61px;
            width: 74%;
        }
        .left_flexer {
            width: 45%;
        }


        .loggers{ background: #fff;}

        .loggers .modal-dialog {
            background: #fff;
            padding: 20px;
            border-radius: 10px;
        }

        .loggers .modal-content {
            border: none;
            text-align: center;
        }

        .w_login h2{ font-size: 32px !important; color: #141928 !important;}
        .w_login img{ margin-top: 50px; margin-bottom: 50px;}
        .w_login p{ color: #787D8C;}
        .W_header h3{ color: #505564; font-size: 18px; font-weight: bold;}
        .W_header{ margin-top: 40px;}
        .W_btns .btans{ border-radius: 4px;
           /*  background: #22A9E0 !important; */
            box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50) !important;
            min-height: 47px;
            padding: 8px 50px 8px 50px !important;
            color: var(--color-white-pure, #FFF);
            text-align: center;
            font-size: 15.5px !important;
            font-weight: 500;
            
            line-height: 16px;
            margin-top: 20px; border: 1px solid #22A9E0;}

            .btan1{ background: #22A9E0; color: #fff !important;}
            .btan2{ background: #fff; color: #22A9E0 !important;}
            .W_btns{ display: flex; gap: 40px; align-items: center; justify-content: center; margin-bottom: 30px;}

            .loggers .modal-body {
                padding: 50px 0px !important;
                padding-bottom: 0px !important;
            }
            .loggers .modal_closer {
                position: absolute;
                right: -5px;
                top: -6px;
                -index: 9999;
                cursor: pointer;
            }