

.budget_drop {
  min-width: 305px !important;
  left: 0 !important;
  padding-bottom: 10px !important;
}

.budget_rad {
  display: flex;
   align-items: inherit !important; 
  gap: 10px !important;
   margin-right: 0px !important;  justify-content: space-between;
}
.left-radio{ width: 100% !important;}
.right-radio{ width: 30% !important;}
.budget_rad .unique-radio-label{ margin-left: 0px !important;}

.budget_rad span {
  
  position: absolute !important;
  top: -3px !important;
  width: 280px !important;
  left: 3px !important;
}
.price_data{ margin-top: 15px !important;}

.dropdown-input {
    background: url(../../../public/assets/arow.svg) !important;
    background-size: 20px 20px !important;
    background-repeat: no-repeat !important;
    background-position: right 1rem center !important;
    cursor: pointer !important;
    background-position: 96px 10px !important;
    z-index: 99999999999999999; font-size: 13px !important;
}


.common-options-container .Priceoption {
  color: var(--color-tertiary-grey, #646978) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 26px !important; 
}
.common-options-container  {
  margin-top: 15px;
}

.dropdown-container .dropdown-container{ cursor: pointer !important;}
.dollar-sign {
  color: #B4B9C8 !important;
  margin-right: 3px !important;
}

.dropdown-container .form-control{ cursor: pointer !important;}










.horizontal-slider {
  width: 100%;
  height: 5px;
  background: var(--color-white-blue, #F0F5FF);  border-radius: 10px;
  margin-top: -3px;
}
.thumb {
  height: 15px;
  width: 15px;
  text-align: center;
  background-color: #00aaff;
  color: #00aaff;
  border-radius: 50%;
  cursor: grab;
  top: -5px;
}

.track {
  background: transparent;
}
.horizontal-slider .track-0 {
  background: #00aaff ;
  height: 5px;
  border-radius: 100px;
}
.horizontal-slider .react-slider-track-1 {
  background: var(--color-white-blue, #F0F5FF); 
}

.thumb:focus-visible {
  outline: #00aaff auto 1px;
}

.budget_rad span {
  position: absolute !important;
  top: 0px !important;
  width: 110px !important;
  left: 3px !important;
  font-size: 11px !important;
}   