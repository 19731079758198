.mutual_popuus .modal{ --bs-modal-width: 900px !important;}

.overall_mutual_modal{ display: flex; flex-wrap: wrap;}
.left_mutual_modal{ width: 50%; padding: 50px;}
.right_mutual_modal{ width: 50%; padding: 70px; border-radius: 0px 10px 10px 0px;
    background: var(--color-white-blue, #F0F5FF); text-align: center;}


    .right_mutual_modal h1{ color: var(--color-secondary-dark, #141928);
        text-align: center;
        
      
        font-size: 32px;
       
        font-weight: 700;
        }
    .right_mutual_modal p{ color: var(--color-tertiary-grey-mid, #787D8C);
        text-align: center; margin: 25px 0px;
        
       
        font-size: 14px;
       
        font-weight: 400;
        line-height: 150%;}
    .right_mutual_modal img{ margin-top: 65px;}


    .left_mutual_modal .inquery h3{ display: none;}
    .left_mutual_modal .dnone{ display: none;}

    .left_mutual_modal .property-detail-form {
        position: static;
       
        transform: none;
        box-shadow: none !important;
        transition: none; padding: 0px; background: none;
    }
    .left_mutual_modal .tcs{ justify-content: left !important;}
    .left_mutual_modal .tcs{ justify-content: center;}
    .left_mutual_modal .property_drop h3{ display: block !important;}

    .free_market form.inq_form {
        height: 500px;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        padding-right: 0px !important;
    }
    
    

    @media (min-width:320px) and (max-width: 1024px) {

        .left_mutual_modal {
            width: 100% !important;
            padding: 26px !important;
        }
        .modal_closer img{ width: 20px;}
        .modal_closer {
           
            right: 6px !important;
            top: 1px !important;
           
        }
        .right_mutual_modal {
            width: 100% !important;
            padding: 23px !important;
           
        }

        .preferences {
            min-width: 200px !important;
            width: 240px;
            left: 0px !important;
        }
        .dream_only .property_drop .options{
             display: block !important;}

             .dream_only .option {
                font-size: 12px !important;
                padding: 3px 0px !important;
                width: 35% !important;
                text-align: center !important;
                margin: 5px 3px !important;
            }
            .dream_only .property-detail-form .budget_drop {
                right: 0 !important;
                overflow: hidden;
                width: 290px !important;
                left: -12px !important;
            }
            .dream_only .right-radio {
                width: 100% !important;
            }
            .dream_only .budget_rad{ display: block;}
            .dream_only .left_input_blocks {
                width: 80% !important;
            }
            .right_input_blocks {
                width: 80% !important;
                
            }
            .modal_closer img {
                width: 24px !important;
            }

        }


    