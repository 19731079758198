.agent_list{ margin-top: 100px;}

.agent_list h2 {
    text-align: center;
    margin-bottom: 55px;
}
.agent_one{ width: 23%; background: #fff; text-align: center; padding: 40px;}
.agent_one img{ height: 150px; width: 150px; border-radius: 500px;}
.agent_one h3 {
    color: #22A9E0;
    text-align: center;
    font-family: Oxygen !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 32px;
}
.agent_one h4{ color: var(--color-secondary-dark, #141928);
    text-align: center;
  
    font-family: Oxygen !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; }
    .agent_one p {
        color: var(--color-tertiary-grey-mid, #787D8C);
        text-align: center;
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 21px;
    }

    .view_agent {
        color: #22A9E0;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: none;
        border: none;
        margin-top: 25px;
    }

    .filtration.d-flex {
        margin-top: 147px;
    }

    .d_set .drop_data {
        position: relative;
        width: 100%; min-width: 183px;
    }
    .filtration.d-flex {
        margin-top: 75px;
        justify-content: right;
        gap: 20px;
        margin-bottom: 30px;
    }

    .pset .drop_data {
        position: relative;
        width: 100%; min-width: 183px;
    }
    .agent_one img{ height: 150px; width: 150px; border-radius: 500px;}
   .no_realtor{
      display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; 
    font-size: 1.2rem;
    color: #555;
   }