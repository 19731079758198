.contact_text{}
.contact_text h1{ color: var(--color-secondary-dark, #141928);

   
    font-size: 58px;
   
    font-weight: 700;
   }
.contact_text p{ color: var(--color-tertiary-grey-mid, #787D8C);

   
    font-size: 14px;
  
    font-weight: 400;
    line-height: 150%; }

    .full_contactus{ padding: 70px 0px; background: var(--color-white-off, #F7F8F9);}
    .d_mis{ align-items: center;}

    .new_form{ margin-top: 70px;}
    .real_estate{ text-align: center;}


    @media (min-width: 320px) and (max-width: 1024px) {
        .full_contactus {
            padding: 30px 0px;
            
        }
        .top_ln{ margin-top: 10px !important;}

    }