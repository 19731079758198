.paginationn {
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    list-style: none;
    margin-top: 60px;
}

.paginationn li{
    height: 32px;
	width: 32px;
	color: var(--color-secondary-dark, #141928);
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	line-height: 28px;
	border-radius: 2px;
	background: var(--color-white-blue, #F0F5FF);
	border: none;
	margin: 5px;

}
.paginationn li a{
	border-radius: 2px;
    color: #141928;
    color: var(--color-secondary-dark,#141928);
    font-size: 16px;
    font-weight: 700;
    height: 32px;
    line-height: 28px;
    margin: 5px;
    text-align: center;
    width: 32px;
	font-family: 'Roboto', sans-serif !important;
}
.paginationn .active {
	border-radius: 2px;
	background: #22A9E0;
	color: #fff;
}

.selected_option {
	background: url(../../../../public//s3.svg) no-repeat;
	background-position: 152px 13px !important;
	border-radius: 5px;
	border: 1px solid var(--color-tertiary-grey-light, #B4B9C8);
	padding: 10px 12px;
}

.agent_dropdown {}

.drop_selection p {
	color: var(--color-tertiary-grey, #646978);


	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.drop_selection {
	padding: 10px 12px;
}


.selected_option {
	background-repeat: no-repeat;
	background-position: right;
	padding: 10px 12px;
	border: 1px solid #B4B9C8;
	cursor: pointer;
}

.drop_data {
	position: relative;
	width: 150px;
}

.drop_data.open .selected_option {
	border: none;
}

.agent_dropdown {}

.drop_selection p {
	color: #646978;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	cursor: pointer;
}

.drop_selection {
	position: absolute;
	width: 100%;
	background: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 10;
	padding: 10px 12;
	top: 100%;
	left: 0;
	border-right: 1px solid #B4B9C8;
	border-bottom: 1px solid #B4B9C8;
	border-left: 1px solid #B4B9C8;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.drop_data.open {
	border-radius: 5px;
	background: transparent;
	border: none;
}

.selected_option {
	background: url(../../../../public//s3.svg) no-repeat;
	background-position: 152px 10px !important;
	border-radius: 5px;
	border: 1px solid var(--color-tertiary-grey-light, #B4B9C8);
	padding: 8px 12px;
	cursor: pointer;
	color: #646978;
	font-size: 15px;
}
.drop_selection p{ margin-bottom: 0px !important; margin-top: 0px !important;}


.drop_data.open .selected_option {
	background-color: #fff;
	border-bottom: none;
	border-top: 1px solid #B4B9C8;
	border-left: 1px solid #B4B9C8;
	border-right: 1px solid #B4B9C8;
	border-radius: 0px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.agent_dropdown {}

.drop_selection p {
    color: var(--color-tertiary-grey, #646978);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    cursor: pointer;
}

.drop_selection {
	position: absolute;
	width: 100%;
	background: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 10;
	padding: 10px 12px;
	top: 100%;
	left: 0;
	border-right: 1px solid #B4B9C8;
	border-bottom: 1px solid #B4B9C8;
	border-left: 1px solid #B4B9C8;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.drop_data.open {
	border-radius: 5px;
	background: transparent;
	border: none;
}


.overall_right_side {
	position: relative;
}

.property_drop_felxing{ justify-content: end; align-items: center; gap: 50px; margin-bottom:40px;}
.property_drop_felxing .blocks_counting{ margin-top: 20px;}

.property_drop_felxing .drop_selection p {
    color: var(--color-tertiary-grey, #646978);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    cursor: pointer;
    padding-left: 0px;
}

.property-overlays{
    z-index: 1;
}

.fff{ margin-top: 60px;}

.no_propertor{ margin-top: 50px !important;}