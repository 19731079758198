.full_abt {
	background: var(--color-white-off, #F7F8F9);
}
.abt_all {
	padding: 70px 0px;
}
.about_company h1 {
	color: var(--color-secondary-dark, #141928) !important;
	font-size: 56px !important;
	font-weight: 700 !important;
	margin-bottom: 50px;
}
.about_company p {
	color: var(--color-tertiary-grey-mid, #787D8C);
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
}
.about_company img {
	margin-bottom: 50px;
}
.about_company {
	position: relative;
}
.abt_all {}
.float-icons1 {
	position: absolute;
	right: -180px;
}
.side_icon_form {
	position: relative;
}
.float-icons2 {
	position: absolute;
	right: -130px;
	top: 50px;
}
.float-icons3 {
	position: absolute;
	right: -100px;
	bottom: -18px;
}
.abt_data h3 {
	color: #22A9E0;
	font-size: 18px;
	font-weight: 700;
}
.abt_data h2 {
	color: var(--color-secondary-dark, #141928);
	font-size: 48px;
	font-weight: 700;
	margin-top: 30px;
}
.abt_data p {
	color: var(--color-tertiary-grey-mid, #787D8C);
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
}
.abt_data {
	margin-top: 20px;
}
.mission_vision_content {
	color: var(--color-tertiary-grey-mid, #787D8C);
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
}
.mission_vision_tab {
	display: flex;
	border-radius: 4px;
	border: 2px solid #22A9E0;
	width: 192px;
	height: 32px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: #22A9E0;
	color: #22A9E0;
	text-align: center;
	margin-top: 40px;
	font-size: 14px;
	margin-bottom: 20px;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	font-family: 'Roboto', sans-serif !important;
}
.mission_vision_tab .active {
	background: #22A9E0;
	color: #fff;
}
.left_mission {
	width: 50%;
	height: 100%;
	padding: 5px;
	cursor: pointer;
}
.right_vision {
	width: 50%;
	height: 100%;
	padding: 5px;
	cursor: pointer;
}
.full_counter {
	border: 1px solid var(--color-secondary-dark-light, #505564);
	background: var(--color-secondary-dark-light, #505564);
	margin-top: 50px;
	padding: 40px 0px;
	padding-bottom: 10px;
}
.counter {
	text-align: center;
}
.counter h2 {
	color: var(--color-white-pure, #FFF) !important;
	margin-top: 10px;
	font-size: 24px !important;
	font-weight: 700 !important
}
.counter p {
	color: var(--color-white-pure, #FFF);
	text-align: center;
	font-size: 12px !important;
	font-weight: 400 !important;
	line-height: 10px;
}
.counter img {}
/* agents-page */
.all_agents h1 {
	color: var(--color-secondary-dark, #141928);
	text-align: center;
	font-size: 56px;
	margin-bottom: 70px;
	font-weight: 700;
}
.agent_search {
	width: 50%;
}
.agents_count {
	width: 50%;
}
.agent_search .form-control {
	border-radius: 5px;
	border: 1px solid var(--color-tertiary-grey-light, #B4B9C8);
	width: 428px;
	padding: 10px 12px;
	background: transparent;
	color: var(--color-tertiary-grey, #646978);
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
}
.agent_search {
	margin-bottom: 24px;
}
.agents_count {
	color: var(--color-secondary-dark, #141928);
	font-family: 'Roboto', sans-serif !important;
	font-size: 18px;
	font-weight: 700;
	text-align: right;
}
.overall_agents {
	flex-wrap: wrap;
}
.overall_agents {
	gap: 28px;
}
.ml {
	margin-right: 5px;
}
.pagination {
	text-align: center;
	margin: 0 auto;
	display: block !important;
	margin-top: 60px;
}
.pagination button {
	height: 32px;
	width: 32px;
	color: var(--color-secondary-dark, #141928);
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	line-height: 28px;
	border-radius: 2px;
	background: var(--color-white-blue, #F0F5FF);
	border: none;
	margin: 5px;
	font-family: 'Roboto', sans-serif !important;
}
.pagination .active {
	border-radius: 2px;
	background: #22A9E0;
	color: #fff;
}
.left_awo {
	transform: rotate(180deg);
}
.disabled-btn img {
	filter: grayscale(1);
	/* This will turn the image into grey */
	opacity: 0.5;
	/* This will make the image a bit transparent */
}
/* agent-page */
.back_page a {
	color: var(--color-secondary-dark-light, #505564);
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	/* 24px */
}
.back_page img {
	margin-right: 10px;
	position: relative;
	top: -1px;
}
.single_agent_info {
	border-radius: 4px 4px 0px 0px;
	background: var(--color-white-pure, #FFF);
	box-shadow: 0px 10px 31px 0px rgba(7, 152, 255, 0.09);
	padding: 40px;
	position: relative;
	margin-top: 30px;
}
.single_agent_info img {
	position: absolute;
	right: 40px;
	width: 150px;
	height: 150px;
	top: -70px;
	border-radius: 100px;
}
.single_agent_info h1 {
	color: var(--color-secondary-dark, #141928);
	font-size: 60px;
	font-weight: 700;
}
.flex_infomation {
	justify-content: space-between;  flex-wrap: wrap;
}
.rt_icons img {
	position: static !important;
	height: 20px;
	width: 20px;
	margin-right: 7px;
}
.rt_agt_info {
	gap: 30px;
	align-items: center;
	color: var(--color-secondary-dark-light, #505564);
	font-size: 16px;
	font-weight: 700;
	line-height: 150%;
}
.lest_agt_info {
	color: var(--color-secondary-dark-light, #505564);
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
}
.single_agent_info p {
	color: var(--color-tertiary-grey-mid, #787D8C);
	font-size: 13px;
	font-weight: 400;
	line-height: 150%;
	margin-top: 25px;
}
.agent_counter {
	flex-wrap: wrap;
	justify-content: space-between;
	border-radius: 0px 0px 4px 4px;
	background: linear-gradient(174deg, #1A90C0 32.79%, #22A9E0 77.31%);
	box-shadow: 0px 0px 31px 0px rgba(7, 152, 255, 0.09);
	padding: 25px 0px;
	padding-bottom: 20px !important;
}
.single_count {
	text-align: center;
}
.single_count h2 {
	color: var(--color-white-pure, #FFF);
	font-size: 30px;
	font-weight: 700;
	margin-top: 10px;
}
.single_count h3 {
	color: var(--color-white-off, #F7F8F9);
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
}
.single_count {
	width: 32%;
}
.property_tab {
	border-bottom: 1px solid #B4B9C8;
	padding-bottom: 0px !important;
}
.overall_property_tabs {
	margin-top: 70px;
	margin-bottom: 50px;
}
.property_tab {
	margin-bottom: 30px;
	padding-bottom: 19px;
}
.property_tab {
	flex-wrap: wrap;
	justify-content: space-between;
}
.left_prop_tab {
	color: var(--color-tertiary-grey-light, #B4B9C8);
	font-size: 18px;
	font-weight: 700;
	width: 48%;
	text-align: center;
	cursor: pointer;
}
.overall_property_tabs .property-card {
	margin-bottom: 15px;
}
.overall_property_tabs .active {
	color: var(--color-secondary-dark, #141928);
	font-size: 18px;
	font-weight: 700;
	border-bottom: 3px solid var(--color-secondary-dark, #141928);
	padding-bottom: 20px;
	position: relative;
	bottom: -2px;
}
.second_overlay {
	color: var(--color-secondary-dark, #141928);
	font-size: 14px;
	bottom: 150px !important;
	left: 10px;
	font-weight: 400;
	line-height: 150%;
	position: absolute;
	bottom: 0px;
	border-radius: 50px;
	background: var(--color-white-blue, #F0F5FF);
	padding: 8px 19px;
}
.prop_tabs_data1 {
	overflow-y: scroll;
	height: 800px;
	overflow-x: hidden;
	padding-right: 15px;
}
.single-agent-full {
	padding: 70px 0px;
	padding-bottom: 0px;
}
.agent_map {

	width: 95%;
	margin-left: 5%;
}
/* dropdown-css */
.selected_option {
	background: url(../../public/assets/s3.svg) no-repeat;
	background-position: 169px 13px !important;
	border-radius: 5px;
	border: 1px solid var(--color-tertiary-grey-light, #B4B9C8);
	padding: 10px 12px;
}
.agent_dropdown {}
.drop_selection p {
	color: var(--color-tertiary-grey, #646978);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.drop_selection {
	padding: 10px 12px;
}
.selected_option {
	background-repeat: no-repeat;
	background-position: right;
	padding: 10px 12px;
	border: 1px solid #B4B9C8;
	cursor: pointer;
}
.drop_data {
	position: relative;
	width: 185px !important;
}
.drop_data.open .selected_option {
	border: none;
}
.agent_dropdown {}
.drop_selection p {
	color: #646978;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	cursor: pointer;
}
.drop_selection {
	position: absolute;
	width: 100%;
	background: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 10;
	padding: 10px 12;
	top: 100%;
	left: 0;
	border-right: 1px solid #B4B9C8;
	border-bottom: 1px solid #B4B9C8;
	border-left: 1px solid #B4B9C8;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.drop_data.open {
	border-radius: 5px;
	background: transparent;
	border: none;
}
.selected_option {
	background: url(../../public/assets/s3.svg) no-repeat;
	background-position: 169px 13px !important;
	border-radius: 5px;
	border: 1px solid var(--color-tertiary-grey-light, #B4B9C8);
	padding: 10px 12px;
	cursor: pointer;
	color: #646978;
	font-size: 15px;
}
.drop_data.open .selected_option {
	background-color: #fff;
	border-bottom: none;
	border-top: 1px solid #B4B9C8;
	border-left: 1px solid #B4B9C8;
	border-right: 1px solid #B4B9C8;
	border-radius: 0px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}
.agent_dropdown {}
.drop_selection p {
	color: var(--color-tertiary-grey, #646978);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	cursor: pointer;
}
.drop_selection {
	position: absolute;
	width: 100%;
	background: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 10;
	padding: 10px 12px;
	top: 100%;
	left: 0;
	border-right: 1px solid #B4B9C8;
	border-bottom: 1px solid #B4B9C8;
	border-left: 1px solid #B4B9C8;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.drop_data.open {
	border-radius: 5px;
	background: transparent;
	border: none;
}
.agent_dropdown {
	margin-top: 77px;
}
.overall_right_side {
	position: relative;
}
.agent_dropdown {
	margin-top: 77px;
	position: absolute;
	right: 0;
	top: -146px;
}
/* dropdown-css */
/* flex-overlays */
.flex_overal {
    position: absolute;
    width: 100%;
    bottom: 150px;
    z-index: 1;
    display: flex;
    left: 9px;
}
/* .first_flex_overaly{ width: 34%;}
 */.second_flex_overlay img{     width: 80px;}
.first_flex_overaly {
    color: var(--color-secondary-dark, #141928);
    font-size: 13.5px;
    font-weight: 400;
    line-height: 150%;
    border-radius: 50px;
    background: var(--color-white-blue, #F0F5FF);
    padding: 6px 8px;
    text-align: center;
}
.second_flex_overlay {
    width: 62%;
    text-align: right;
    margin-top: 7px;
}








@media (min-width: 1280px) and (max-width: 1400px) {
	.yaya{     margin-left: 6.666667% !important;     flex: 0 0 auto;
		width: 43% !important;}
	.flex_overal {
		bottom: 154px;
	}
	.first_flex_overaly {
		width: 49%;
	}
	.first_flex_overaly{ font-size: 13px;}
	.second_flex_overlay img {
		width:50px;
	}
	.second_flex_overlay {
		width: 44%;
	}
	.float-icons2 {
		right: -43px;
	}
	.float-icons3 {
		position: absolute;
		right: -74px;
		bottom: -18px;
	}
	.agent_one {
		width: 31%;
	}
}
@media (min-width: 1025px) and (max-width: 1279px) {
	

	.yaya{     margin-left: 4.666667% !important;     flex: 0 0 auto;
		width: 45% !important;}

	.property_drop {
		
		left: -165%;
	
	}


	.left_prop_tab {
		font-size: 16px;
	}
	.overall_property_tabs .active {
		font-size: 16px;
	}
	.flex_overal {
		bottom: 174px;
	}
	.first_flex_overaly {
		width: 49%;
	}
	.first_flex_overaly{ font-size: 13px;}
	.second_flex_overlay img {
		width:50px;
	}
	.second_flex_overlay {
		width: 44%;
	}
	.float-icons2,
	.float-icons1,
	.float-icons3 {
		display: none;
	}
	/* aboutus-pages */
	.agent_one {
		width: 31%;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {

	.popular_form .pppp .property_drop {
		width: 199px !important;
		left: 0%;
		min-width: 229px;
	}
	.popular_form .property-detail-form .budget_drop {
		right: 20px;
		overflow: hidden;
		left: -30px !important;
		margin-left: 0px;
		width: 100px !important;
		min-width: 294px !important;
	}

	.popular_form .right_input_blocks {
		position: relative;
		width: 48% !important;
	}
	.agent_one h3 {
		
		font-size: 16px !important;
		
	}
	.agent_one h4{font-size: 14px !important;}


	.agent_one {
		width: 23%;
		margin-top: 30px;
	}
	.overall_agents {
		gap: 15px;
	}



	.coller .right_input_blocks {
 
		width: 48% !important;
	}

	.flex_overal {
		bottom: 200px;
	}
	.second_flex_overlay img {
		width:50px;
	}
	.second_flex_overlay {
		width: 44%;
	}
	.about_company h1 {
		font-size: 36px !important;
	}
	.float-icons2,
	.float-icons1,
	.float-icons3 {
		display: none;
	}
	/* agents-page */
	.all_agents h1 {
		font-size: 35px;
		margin-bottom: 40px;
	}
	.agent_one {
		width: 47%;
	}
       /* single-agent */
       .single_agent_info h1 {
        font-size: 40px;
    }
    .lest_agt_info {
        font-size: 15px;
    }
    .single_count h3{ font-size: 14px !important;}
    .second_overlay{ bottom: 230px !important;}
}
@media (min-width: 352px) and (max-width: 1024px) {

	
	.about_company h1 {
		font-size: 36px !important;
	}
	.cta-two button {
		margin-top: 0px !important;
	}
}
@media (min-width: 320px) and (max-width: 767px) {

	.popular_form .pppp .property_drop {
		width: 199px !important;
		left: 0%;
		min-width: 229px;
	}
	.popular_form .property-detail-form .budget_drop {
		right: 20px;
		overflow: hidden;
		left: -30px !important;
		margin-left: 0px;
		width: 100px !important;
		min-width: 294px !important;
	}

	.popular_form .right_input_blocks {
		position: relative;
		width: 48% !important;
	}


	.coller .right_input_blocks {
 
		width: 48% !important;
	}


	.col-lg-3.col-md-3.col-sm-6.counter {
		width: 50%;
	}
	.about_company h1 {
		font-size: 32px !important;
	}
	.abt_all {
		padding: 20px 0px;
	}
	.float-icons2,
	.float-icons1,
	.float-icons3 {
		display: none;
	}
	/* agents-page */
	.all_agents h1 {
		margin-bottom: 30px !important;
	}
	.agent_search .form-control {
		width: 297px;
	}
	.agents_count {
		font-size: 13px;
		margin-top: 60px;
	}
    /* single-agent */
	.flex_overal {
		bottom: 200px;
	}
	.second_flex_overlay img {
		width:50px;
	}
	.second_flex_overlay {
		width: 44%;
	}
	.first_flex_overaly {
		width: 50%;
	}
    .single_agent_info img {
        position: static;
    }
    .lest_agt_info{ width: 100%;}
    .rt_agt_info{ width: 100%; flex-wrap: wrap; margin-top: 15px; gap: 10px;}
    .single_agent_info {
        padding: 15px;
    }
    .single_agent_info h1 {
        font-size: 21px !important;
        margin-top: 20px;
    }
    .single_count {
        width: 100%;
        margin-top: 20px;
    }
    .left_prop_tab {
        font-size: 14px;
    }
    .overall_property_tabs .active {
        font-size: 14px;
    }
    .overall_property_tabs {
        margin-top: 40px;
    }
    .second_overlay {
        font-size: 12px;
        bottom: 200px !important;
        padding: 6px 12px;
    }
    .overall_property_tabs {
        margin-bottom: 0px;
    }
    .agent_dropdown {
        margin-top: 67px;
        position: absolute;
        right: 0;
        top: -156px;
    }
    .lest_agt_info{ font-size: 13px;}
}
/* advanced-search */
.advanced_searchs{ padding: 50px;}
.flex_form_row{ display: flex; justify-content: space-between; margin-top: 10px;}
.advanced_searchs h2{ text-align: center; color: var(--color-secondary-dark, #141928); margin-bottom:25px;
	text-align: center;
	font-size: 32px !important;
	font-weight: 700;
	}
	.flex_half_row{ width: 48%;}
	.row.adv_sch_only {
		margin-top: 20px;
	}
	.adv_sch_only .sel_icon{ display: none;}
	.adv_sch_only .select_custom{ margin-bottom: 15px;}
	/* checkbox-ui */
	.custom-checkbox-container {
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
		color: var(--color-tertiary-grey, #646978);
		margin-top: 20px;
		font-size: 14px;
		font-weight: 400;
	  }
	  .custom-checkbox {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	  }
	  .checkmark {
		position: relative;
		height: 20px; 
		width: 20px; 
		background-color: #fff;
		border-radius: 2px;
		border: 2px solid #22A9E0; 
		margin-right: 10px;
	  }
	  .custom-checkbox:checked ~ .checkmark {
		background-color: #22A9E0;
	  }
	  .checkmark:after {
		content: "";
		position: absolute;
		display: none;
	  }
	  .custom-checkbox:checked ~ .checkmark:after {
		display: block;
	  }
	  .custom-checkbox-container .checkmark:after {
		left: 6px; 
		top: 2px; 
		width: 5px; 
		height: 10px; 
		border: solid white;
		border-width: 0 2px 2px 0; 
		transform: rotate(45deg);
	  }
		/* checkbox-ui */
		/* beds_dropdown */
		.beds_list{ min-width: 192px !important; padding :10px 20px !important; left: 0 !important;}
		.custom_drop_list{ line-height: 30px;}
		/* beds_dropdown */
		.single_drop_check{ margin-top: 15px;}
		.single_rad_btn{ display: flex;}
		.radious{ width: 50%;}
		.single_rad_btn span{ width: 320px !important;}
		.single_rad_btn .peros {
			margin-left: 23px;
			margin-top: 3px !important;
			position: relative;
			top: -4px;   color: var(--color-tertiary-grey, #646978);
			font-size: 12px;
			font-weight: 400;
			line-height: 150%; 
		}
		.sub_options_house {
			margin-top: 10px;
		}
		.peros {
			width: 200px;
		}
		.motial{}
		.motial h3, .hoa_fees h3, .open_xcloser h3{ color: var(--color-secondary-dark, #141928);  font-family: 'Oxygen', sans-serif !important;
			font-size: 16px;
			font-weight: 700;
			line-height: 150%;  margin-top: 25px;}
			.motial .right_swap span { color: var(--color-tertiary-grey, #646978);
				font-size: 12px;
				font-weight: 400;
				line-height: 150%;  top: -7px;
			}
			.topper {
				margin-top: 58px !important;
			}


			/* .search_forming{ margin-top: 55px; padding-top: 15px; padding-bottom: 15px;} */
			.search_forming{
					margin-left: 100px;
                    width: 190px;
                    margin-top: 55px;
                    padding-top: 15px;
                     padding-bottom: 15px;
			}
		
			
			.chavron h3{ color: var(--color-tertiary-grey, #646978);
				text-align: right;
				/* Body More Small */
				font-family: Oxygen;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 18px */}
				.chavron .left_commerc {
					margin-top: 17px;
				}
				.chavron .comercial_block {
					align-items: center;
					justify-content: right;
				}
				.hidebtn{ justify-content: center !important;}
				.hidden_submit{  display: flex; justify-content: center;}
.agent_service_area{
margin-top: 78px;
    position: absolute;
    right: 169px;
    top: -146px;
}