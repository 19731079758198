


.image_blocks.imageblock1 {
    position: relative; 
    background-image: url('./../../../public/assets/property-type-home-page/p1.jpeg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    height: 276.66px; 
    width: 100%;  margin-top: 148px; border-radius: 4px;
}




.image_blocks.imageblock1::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(20, 25, 40, 0.4);
    transition: background-color 0.3s ease-in-out; border-radius: 4px;
}

.image_blocks.imageblock1:hover::before {
    background-color: rgba(20, 25, 40, 0.7);
}
.space-type {
    margin-top: 150px;
}
.property-details{ position: relative;}


.image_blocks.imageblock1 {
  
    position: relative;
    padding: 30px; 
}

.property-details h2 { color: var(--color-white-pure, #FFF);
  
    font-size: 20px !important;
  
    font-weight: 700; margin-top: 5px;
    
    
}

.small-text {
    font-size: 12px;
    display: block;   color: var(--color-white-pure, #FFF);
   

    font-size: 12px;
   
    font-weight: 400;
    line-height: 150%; 
}

.bottom-links {
    position: absolute;
    bottom: 20px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.more-properties {
    color: white; 
    text-decoration: none;   color: var(--color-white-pure, #FFF);
  
   
    font-size: 12px;
   
    font-weight: 400;
    line-height: 150%; 
   
}



.play-icon {
    font-size: 16px; 
    color: white; 
}


.imageblock2{  position: relative; 
    background-image: url('./../../../public/assets/property-type-home-page/p2.jpeg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    height: 583.31px; 
    width: 100%; position: relative; border-radius: 4px;
    padding: 30px;  }
    .image_blocks.imageblock2::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(20, 25, 40, 0.4);
        transition: background-color 0.3s ease-in-out;border-radius: 4px;
    }

    .image_blocks.imageblock2:hover::before {
        background-color: rgba(20, 25, 40, 0.7);
    }





.imageblock3{  position: relative; 
    background-image: url('./../../../public/assets/property-type-home-page/p3.jpeg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    height:276.66px; 
    width: 100%; position: relative; border-radius: 4px;
    padding: 30px;  }
    .image_blocks.imageblock3::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(20, 25, 40, 0.4);
        transition: background-color 0.3s ease-in-out; border-radius: 4px;
    }

    .image_blocks.imageblock3:hover::before {
        background-color: rgba(20, 25, 40, 0.7);
    }

    

    .imageblock4{  position: relative; 
        background-image: url('./../../../public/assets/property-type-home-page/p4.jpeg');
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        height:276.66px;  border-radius: 4px;
        width: 100%; position: relative;
        padding: 30px;     margin-top: 30px; }
        .image_blocks.imageblock4::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(20, 25, 40, 0.4); 
            transition: background-color 0.3s ease-in-out; border-radius: 4px;
        }
    
        .image_blocks.imageblock4:hover::before {
            background-color: rgba(20, 25, 40, 0.7);
        }
    


        .imageblock5{  position: relative; 
            background-image: url('./../../../public/assets/property-type-home-page/p5.jpeg');
            background-position: center; 
            background-repeat: no-repeat; 
            background-size: cover;
            height:276.66px; 
            width: 100%; position: relative; border-radius: 4px;
            padding: 30px;  }
            .image_blocks.imageblock5::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(20, 25, 40, 0.4);
                transition: background-color 0.3s ease-in-out; border-radius: 4px;
            }
        
            .image_blocks.imageblock5:hover::before {
                background-color: rgba(20, 25, 40, 0.7);
            }



            .imageblock6{  position: relative; 
                background-image: url('./../../../public/assets/property-type-home-page/p6.jpeg');
                background-position: center; 
                background-repeat: no-repeat; 
                background-size: cover;
                height:276.66px;  border-radius: 4px;
                width: 100%; position: relative;
                padding: 30px;     margin-top: 30px; }
                .image_blocks.imageblock6::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(20, 25, 40, 0.4);
                    transition: background-color 0.3s ease-in-out;
                }
            
                .image_blocks.imageblock6:hover::before {
                    background-color: rgba(20, 25, 40, 0.7);
                }
            
            
        
        
 .image_text{ margin-top: 50px; position: relative;}   
.image_text h3{ color: var(--color-secondary-dark, #141928);
  
    font-size: 24px;
   
    font-weight: 700;
    }
.image_text p{ color: var(--color-tertiary-grey-mid, #787D8C);
  
    font-size: 16px;
   
    font-weight: 400;
    line-height: 150%; }
    

    .image_text::after {
        background: #141928;
        height: 1px;
        width: 83px;
        content: "";
        position: absolute;
        top: 200px;
    }
