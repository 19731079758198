.banner {
    background-image: url('/public/assets/banner.png');
    background-size: cover; 
    background-position: center;  
    background-repeat: no-repeat;  
    height:320px;  
    display: flex;
    justify-content: center;
    align-items: center;  
    color: white;  
    font-size: 24px; 
}

.banner h1{ text-align: center;
    /* Heading 1 */
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;}
