.overall_location h2{ text-align: center; font-weight: 700; font-size: 36px;}
.viewbylocatioon{ border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 10px 31px 0px rgba(7, 152, 255, 0.09); padding: 50px; margin-top: 100px;}
 
    .single_prop_listing img {
        width: 100%; 
        height: auto; 
    }
    .single_prop_listing {
        width: 23%; 
        position: relative;
        display: inline-block; 
    }
    .location_prop_listing {
        flex-wrap: wrap;
        gap: 27px;
    }
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 94%; margin-left: 3%; margin-top: 3%;
    height: 100%; 
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    font-size: 14px;
    text-align: center;
    z-index: 9999; box-shadow: 0px 10px 31px 0px rgba(7, 152, 255, 0.09);
}
    .single_prop_listing:hover .overlay {
        opacity: 1; 
    }
    .overlay a {
        text-decoration: none; 
    }
    .overlay {
        display: block;
        padding-top: 18px;
        height: 94%;
    }
    .overlay p {
        color: #4b4b4b;
        line-height: 11px;
    }
    .overlay a{ color: #212121;}
    .all_locations {
        text-align: right;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 30px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 150%;
        display: block;
        color: #4b4b4b; background: none; border: none;
    }
    .single_prop_listing:hover .prop_btn{  display: none;}




       .prop_btn {
        border-radius: 50px;
        background: #F0F5FF;
        padding: 8px 16px;
        display: block;
        border: none;
        font-size: 14px;
        font-weight: 400;
        margin-top: -53px;
        margin-left: 19px;
        z-index: 9999;
        position: absolute;
    }