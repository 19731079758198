.custom-footer {
    padding: 20px;
    background-color: #141928;
    margin-top: 120px;
    padding: 80px 0px;
    padding-bottom: 30px;
}

.footer-heading {
    margin-bottom: 35px;
    color: var(--color-white-pure, #FFF);
    font-size: 16px;
    font-weight: 700;
}

.footer-subheading, .footer-link, a {
   
    text-decoration: none !important;
   
}

.footer-link i, .footer-address i {
    margin-right: 8px; color: var(--color-white-off, #F7F8F9);
   
   
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}
.footer-link a{ color: var(--color-white-off, #F7F8F9);
   
   
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; }
    .footer-address{ color: var(--color-white-off, #F7F8F9);
   
   
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; }

.footer-newsletter {
    display: flex;
    margin-top: 15px; 
}

.footer-newsletter input[type="text"] {
    flex: 1;
    padding: 8px;
   font-size: 14px; -webkit-border-top-left-radius: 4px;
   -webkit-border-bottom-left-radius: 4px;
   -moz-border-radius-topleft: 4px;
   -moz-border-radius-bottomleft: 4px;
   border-top-left-radius: 4px;
   border-bottom-left-radius: 4px;
    border: none;
     height: 42px; color: var(--color-tertiary-grey-mid, #787D8C);

}

.footer-newsletter button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
}

.footer-social-icons i {
    font-size: 16px;
    margin: 5px;
    cursor: pointer;
}


.footer-social-icons i {
    background: #505564;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    text-align: center;
    padding: 11px;
    color: #fff;
}


.newsletter{ margin-top: 70px;}
.newsletter h3{ 
    font-size: 20px !important;
   color: #fff;
    font-weight: 700 !important;
   }
.newsletter p{ color: var(--color-white-pure, #FFF);
  
  
   
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; }

    .footer-newsletter button {
        border-radius: 4px;
        background: #22A9E0;
        box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);
        padding: 0px 35px;
        margin-left: -3px;
    }

    .client-box{ border-radius: 10px;
        border: 2px solid #22A9E0;
        background: var(--color-white-pure, #FFF);
        box-shadow: 0px 0px 20px 0px rgba(128, 195, 66, 0.50); padding: 40px 20px;
        justify-content: center;
       }

       .client-box {
        background: #fff;
        width: 19%; text-align: center;
    }

    .foot_clients{ justify-content: space-between;}
    .foot_clients {
        justify-content: space-between;
        margin-top: 80px;
    }

    .footer_text {
        color: var(--color-white-off, #F7F8F9);
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin-top: 60px;
    }

    .footer-copyright {
        color: var(--color-white-off, #F7F8F9);
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin-top: 110px;
        margin-left: 40px;
    }

    .footer_links{ justify-content: right; margin-top: 30px; margin-left: 20px;}
    .footer_links a {
        color: var(--color-white-off, #F7F8F9);
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        display: inline-block;
        padding: 10px;
    }