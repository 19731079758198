.mutual_popuus .modal{ --bs-modal-width: 900px !important;}
.advance_search_done{ padding: 20px;}
.advance_search_done h2{ color: var(--color-secondary-dark, #141928) !important;
   margin: 0 auto;
    font-size: 32px !important;
    font-weight: 700 ;
  }
    @media (min-width:320px) and (max-width: 1024px) {
        }
    .advance-suggestion-container {
        
    width: 43%;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* width: 100%; */
    max-height: 201px;
    overflow-y: auto;
    z-index: 999;
    padding-left: 10px;
    border-radius: 15px;
    border-left: 20px;

      }
      /* Add more specific CSS selectors if needed */
      .advance-suggestion-container ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
      .advance-suggestion-container li {
        padding: 8px 12px;
        cursor: pointer;
      }
      .advance-suggestion-container li:hover {
        background-color: #f5f5f5;
      }
