
  @import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

@import '~font-awesome/css/font-awesome.min.css';


body{ font-family: 'Oxygen', sans-serif !important; background: #F7F8F9 !important;
}

h1, h2, h3, h4, h5{ font-family: 'Roboto', sans-serif !important;}

/* For screens larger than 1200px */
@media (min-width: 1200px) {
  .container-fluid {
      width: 90% !important;
  }
}

/* For screens larger than 992px and smaller than 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
  .container-fluid {
      width: 100%;
  }
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  .container-fluid {
      width: 100%;
  }
}


a{ font-family: 'Oxygen', sans-serif !important;}
p{ font-family: 'Oxygen', sans-serif;}

.property-link {
  text-decoration: none;
  color: inherit;
}



.mutual_btn a{ border-radius: 4px;
  border: 1px solid #141928; padding: 13px 50px; font-size: 15px;
  font-style: normal;
  font-weight: 500; color:  #141928; font-family: 'Roboto', sans-serif !important; text-decoration: none;
}

.mutual_btn a {
  transition: background 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  /* Add your other styles for the link here */
}

.mutual_btn a:hover {
  background: #22A9E0;
  border: 1px solid #22A9E0;
  color: #fff;
}


h2{ 
  font-size: 40px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  }


  .overall_nav.homepage .navbar {
    background-color: transparent;  
}

.overall_nav.otherpage {
    background-color: #141928;   padding: 0px 0px;
}


.row.set_block {
  /* margin-top: 80px; */
  margin-bottom: 10px;
  
  font-size: 18px; color: red;
}

/* iconmoon */


@font-face {
  font-family: 'icomoon';
  src:  url('');
  src:  url('../src/public/assets/fonts/icomoon.eot') format('embedded-opentype'),
    url('../src/public/assets/fonts/icomoon.ttf') format('truetype'),
    url('../src/public/assets/fonts/icomoon.woff') format('woff');
   
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-train:before {
  content: "\e900";
  color: #787d8c;
}
.icon-car:before {
  content: "\e901";
  color: #787d8c;
}
.icon-Vector-2:before {
  content: "\e902";
  color: #787d8c;
}
.icon-Vector-1:before {
  content: "\e903";
  color: #787d8c;
}
.icon-Vector:before {
  content: "\e904";
  color: #787d8c;
}




.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #F0F5FF !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.nav-link:focus, .nav-link:hover {
  color: #fff !important;
}
.modal{z-index: 999999999999 !important;}

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%; 
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; 
  height: 40px; 
}

.scroll-to-top-button i {
  font-size: 20px; 
}

