.search-form {
    background: #fff;
    padding: 30px 0px;
    box-shadow: none;
    margin-top: 0px !important; border-radius: 0px; padding-bottom: 10px;
}



.searched_properties .property-card {
   
    margin-bottom: 24px;
  
}



.search-form {
    padding-top: 0px;

    opacity: 1;
    transform: translateY(0);
    transition: transform 0.3s ease, opacity 0.3s ease; 
}
.sticky {
    position: fixed !important;
    top: -50px; 
    opacity: 0; 
    width: 100%;
    z-index: 1000;
    background-color: #22A9E0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    animation: slideDownFadeIn 0.5s forwards 0.1s ease-out;
}

.all_form_flex{ flex-wrap: wrap; justify-content: space-between;}
.single_entry {
    width: 13.8%;
}
.set_block {
    max-height: 800px; /* Adjust this to a height value that suits your design */
    overflow-y: auto;
}

.set_block {
    display: flex;
   
    white-space: nowrap;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #B4B9C8 #B4B9C8; /* For Firefox */
}

::-webkit-scrollbar {
    width: 4px; /* for the width */
    height: 30px !important; /* for the height */
}

::-webkit-scrollbar-thumb {
    background-color: #646978; 
}
::-webkit-scrollbar-track {
    background-color: #B4B9C8;  width: 4px; height: 30px;
}


@keyframes slideDownFadeIn {
    to {
        top: 0;
        opacity: 1;
    }
}

.blocks_counting{ font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; text-align: right; margin-bottom: 20px;}

    .search-form span.input-group-text {
     
        background: #fff !important;
    }


    .sticky .custom_search{ background: #141928 !important; }
    .select_custom {
        position: absolute;
       /* Adjust this value as needed */
    }
    .search-form {
      position: relative;
       padding: 30px 0px;
    box-shadow: none;
    margin-top: 0px !important; border-radius: 0px; padding-bottom: 10px;
        /* position: absolute; */
        top: 20;
        left: 0;
        right: 0;
        /* background-color: rgba(255, 255, 255, 0.8);  */
        z-index: 1000;
    }
    .suggestion1-container {
        position: absolute;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        width: 26%;
        max-height: 497px;
        overflow-y: auto;
        z-index: 999;
        padding-left: -5px;
        border-radius: 15px;
        border-left: 19px;
      
    }
    .single_search_area {
        margin-bottom: 20px;
        position: relative;
      }
      
      .form-control {
        width: 100%;
      }
      
     
      
      .form {
        /* Add the following styles to position the form */
        margin-top: 20px; /* Adjust the margin as needed */
      }
      
      .all_form_flex {
        display: flex;
      }
      
     
      
      .last_entrty {
        margin-right: 0;
      }
      
      .top {
        margin-top: 10px;
      }
      .form-control{
        width: 318px;
height: 41px;
flex-shrink: 0;
      }
      
.single_suggestion {
    border-bottom: 1px solid #F7F8F9;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .suggestions_sidebar {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 497px;
    overflow-y: auto;
    z-index: 999;
    padding-left: -5px;
    /* border-radius: 15px; */
    border-left: 19px;
    background: #fff;
    width: 382px;
    padding: 25px;
    z-index: 999999;
    height: 100vh;
    overflow-y: auto;
  }
  .suggestions_sidebar .single_search_area {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .main_suggestion{ justify-content: space-between;}
  .phone_sugges{ margin-left: 20px;}
  
  .single_suggestion h3{ color: var(--color-secondary-dark-light, #505564);
  
    
    font-size: 18px;
   
    font-weight: 700;
   }
  .single_suggestion p{ color: var(--color-tertiary-grey-mid, #787D8C); 
  
   
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; margin-top: -6px;}
  
    .left_sugg{ color: var(--color-secondary-dark-light, #505564);
  
    
      font-size: 14px;
     
      font-weight: 400;
      line-height: 150%; }
  
      .right_sugg{ color: var(--color-tertiary-grey-mid, #787D8C);
  
       
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;}
  
      .span_count{ color: var(--color-tertiary-grey-light, #B4B9C8) !important;
      }
  
      .suggestions_sidebar .sear_icon {
        position: absolute;
        top: 9px;
        right: 44px;
    }
  
    .suggestions_sidebar .single_search_area .form-control {
     
      padding-right: 60px;
  }
  
  
  
  
  .cross_icon {
    position: absolute;
    right: -30px;
  }