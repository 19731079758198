
.select_custom {
  background: url('../../../public/assets/arow.svg') !important;
  background-size: 20px 20px !important;  /* Adjust the width and height values as per your requirement */
  background-repeat: no-repeat !important;
  background-position: right 1rem center !important;  /* Adjust positioning if needed */ 
  cursor: pointer;
}

.select_custom{ position: relative; display: flex; gap: 15px;}
.select_custom {
  height: 42px;
  border: 1px solid #F0F5FF;
  border-radius: 5px;
  color: #646978;
  font-size: 14px;
  padding-left: 4px;
  align-items: center;
  padding-left: 14px;
}
.property_drop {
position: absolute;
border-radius: 10px;
background: var(--color-white-pure, #FFF);
box-shadow: 0px 8px 10px 0px rgba(20, 25, 40, 0.25);
min-width: 468px;
top: 42px;
left: -50%;
z-index: 9999; padding: 12px;
}
.option {
display: inline-block;
font-size: 14px;
margin: 10px;
border-radius: 50px;
border: 1px solid var(--color-tertiary-grey, #646978); padding: 5px 20px;

}

.option.selected {
background-color: #22A9E0 ; color: #fff;
}

.property_drop h3 {
color: var(--color-tertiary-grey, #646978);
font-family: 'Oxygen', sans-serif !important;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%;
margin-bottom: 0px;
margin-top: 7px;
}

.comercial_block{ align-items: center; gap: 5px;}

.chevron img {
transform: rotate(360deg);
margin-top: 7px;
}
.left_commerc{
  display: flex;
   
}
.boxx{
   margin-top: 7px;
    margin-right: 8px;
}

.sel_text {
      width: 63%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
