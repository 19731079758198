.property-card {
    overflow: hidden;
    max-height: 100%;
 
  }
.property-image{
    height: 214px;
     width: 297px;
}

.gmap {
  position: relative;
  /* top: 99px; */
 
}

.gm-style-iw{ padding: 0px !important;}
.gm-style img {
  max-width: none;
  width: 100% !important;
}

.gm-style .gm-style-iw-d{ overflow: visible !important;}
button.gm-ui-hover-effect {
  background: #22A9E0!important;
  opacity: 1;
  color: #fff !important;
}

.gm-ui-hover-effect>span {
  background-color: #fff;
  margin: 5px !important;
  margin-top: 10px !important;
  font-weight: bold !important;
}

.property-cards .amenities{ align-items: center;}
.property-cards .single_amenities img{ width: 14px !important;}
.property-cards  .left_amenities {
  margin-top: 6px !important;
}
.property-cards .bottom_property {
  padding: 10px !important;
}


.property-cards .location_name {
 
  margin-top: -2px; width: 255px;
}
